<template>
  <mobile-screen :header="true" screen-class="profile-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="connectors-header">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName("connectors", "connector-types", "connector-types")
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper connectors-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('connectors', 'connector-types', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfConnectorTypes && listOfConnectorTypes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="conn in listOfConnectorTypes"
        :key="conn.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectConnectorType(conn)"
        >
          <span class="label">{{ conn.name }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                :icon="[
                  conn.id === selectConnectorId
                    ? '#cx-app1-checkmark'
                    : '#cx-app1-arrow-right-12x12'
                ]"
                :width="[conn.id === selectConnectorId ? '20' : '12']"
                :height="[conn.id === selectConnectorId ? '20' : '12']"
              ></icon>
            </span>
          </span>
        </button>
      </li>
      <li class="clebex-item-section-item">
        <button class="clebex-item-content-wrapper" @click="openIntranet()">
          <span class="label">{{
            displayLabelName("connectors.intranet.intranet")
          }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                :icon="[
                  intranetOpened
                    ? '#cx-app1-checkmark'
                    : '#cx-app1-arrow-right-12x12'
                ]"
                :width="[intranetOpened ? '20' : '12']"
                :height="[intranetOpened ? '20' : '12']"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "ConnectorTypes",
  data() {
    return {
      selectConnectorId: null,
      processing: false,
      searchQuery: "",
      subdomain: null,
      intranetOpened: false
    };
  },
  created() {
    this.getConnectorTypes();
    this.subdomain = getSubdomain();
  },
  computed: {
    ...mapState("connector", ["connectorTypes"]),
    listOfConnectorTypes() {
      const query = this.searchQuery;
      if (
        this.connectorTypes &&
        this.connectorTypes.data &&
        this.connectorTypes.data.length
      ) {
        if (query && query.length > 1) {
          return this.connectorTypes.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.connectorTypes.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("connector", [
      "getConnectorTypes",
      "setSelectedConnectorType",
      "getConnectors"
    ]),
    selectConnectorType(connector) {
      this.selectConnectorId = connector.id;
      this.intranetOpened = false;
      this.goToConnectors();
    },
    openIntranet() {
      this.selectConnectorId = null;
      this.intranetOpened = true;
      this.$router.push({ name: "r_connectors-intranet" });
    },
    goToConnectors() {
      this.setSelectedConnectorType(
        this.connectorTypes.data.filter(
          item => item.id === this.selectConnectorId
        )
      );
      this.getConnectors(this.selectConnectorId);
      this.$router.push({
        name: this.subdomain ? "r_connectors-company" : "r_connectors"
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
